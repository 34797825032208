/* -------begin customization-------- */
/* change the primary theme color to red */

@import "custom-variables";

/* -------end customization-------- */
/* finally, import Bootstrap to set the changes! */
// @import "bootstrap/bootstrap";
@import "custom-bootstrap/bootstrap-custom";

.font-brandon {
	font-family: $font-family-brandon;
}

.font-sancez {
	font-family: $font-family-sanchez;
}

.size-sm {
	font-size: $font-size-sm;
}

.size-lg {
	font-size: $font-size-lg;
}

.size-md {
	font-size: $font-size-md;
}
/* 
@import "bootstrap/functions";
@import "bootstrap/mixins/breakpoints";
@import "bootstrap/variables";
@import "bootstrap/utilities/flex";
@import "bootstrap/utilities/display"; */

$ph-bg: $white !default;
$ph-color: $gray-500 !default;
$ph-border-radius: 2px !default;

$ph-gutter: 30px !default;
$ph-spacer: 15px !default;

$ph-avatar-border-radius: 50% !default;

$ph-animation-duration: 0.8s !default;

@import "~placeholder-loading/src/scss/placeholder-loading";

.custom-selectpicker {
	.bootstrap-select {
		& + .animated-background {
			display: none;
		}

		button,
		&.open button {
			background-color: $white;
			border: 1px solid $gray-300;
			color: $dark;
			box-shadow: none;
			&:hover,
			&:focus,
			&:visited {
				border-color: $gray-300;
				background-color: inherit;
				// color: inherit;
				box-shadow: none;
			}

			span {
				text-transform: none;
				font-size: 14px;
			}
		}

		span {
			letter-spacing: normal;
		}

		.dropdown-menu {
			&.inner {
				li {
					&.selected {
						& > a {
							&,
							&:hover {
								background-color: $blue;
								color: $white;
							}

							&:focus {
								background: darken($blue, 5%);
								color: $white;
							}

							img {
								filter: brightness(0) invert(1);
							}
						}
					}

					& > a {
						padding: 5px 15px;
						&:hover {
							background-color: lighten($blue, 10%);
							background-image: none;
							color: $white;
							img {
								filter: brightness(0) invert(1);
							}
						}

						&:focus {
							background: darken($blue, 5%);
							color: $white;
						}

						span {
							text-transform: none;
							font-size: inherit;
						}
					}
				}
			}
		}

		.dropdown-menu,
		.filter-option {
			img {
				height: 20px;
				width: auto;
			}
		}
	}
}

.container-banner {
	padding-top: 100%;
	background-size: cover;
	@include media-breakpoint-up(sm) {
		padding-top: 30%;
	}
	.container-image-head {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	.text-middle {
		font-family: "brandon-grotesque", Arial, Helvetica, sans-serif;
		font-size: 3rem;
		font-weight: 700;
		~ button {
			font-family: "brandon-grotesque", Arial, Helvetica, sans-serif;
			font-size: 1.5rem;
			font-weight: 700;
		}
	}
}

.cut-text {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.image-centered {
	display: block;
	height: 0;
	position: relative;
	overflow: hidden;
	&.image-centered--square {
		padding-bottom: 100%;
		.centered-container {
			img {
				&.orientation-landscape {
					&.aspect-standard {
						height: 100%;
						width: auto;
					}
				}
			}
		}
	}

	&.image-centered--21-9 {
		padding-bottom: percentage(9/21);
	}

	&.image-centered--16-9 {
		padding-bottom: percentage(9/16);
	}

	&.image-centered--4-3 {
		padding-bottom: percentage(3/4);
	}

	.centered-container {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		transform: translate(50%, 50%);
		img,
		canvas {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			height: auto;
			width: 100%;
			max-width: none;
			transform: translate(-50%, -50%);
			&.orientation-landscape {
				height: 100%;
				width: auto;
				&.aspect-standard {
					height: auto;
					width: 100%;
				}
			}
		}
	}
}

.slick-disabled {
	display: none !important;
}

/* Product */
.product {
	.product__item {
		outline: none;
		/* Height slider */
		.product__image img {
			height: auto;
		}
		/* Size arrow */

		.content-product__avatar {
			background-color: $white;
			position: absolute;
			overflow: hidden;
			bottom: -15px;
			right: -15px;
			width: 45px;
			border-radius: 5px;
			transition: opacity 0.15s ease-in-out;
			@include media-breakpoint-up(sm) {
				opacity: 0;
			}
		}

		/* Hover animation */
		@include media-breakpoint-up(sm) {
			.content-detail__hide-item {
				overflow: hidden;
			}

			&:hover {
				.content-detail__list-thumbnail {
					top: 0;
				}

				.content-product__avatar {
					opacity: 1;
				}
			}

			.content-detail__list-thumbnail {
				position: relative;
				top: -7rem;
				transition: top 0.15s ease-in-out;
			}
		}
		/* END: Hover animation */
	}
}
/* END: Product */

/* Content Detail Product */
.content-detail {
	.content-detail__thumbnail {
		//     min-height: 4rem;
		//     .item-thumbnail {
		//         outline: none;
		//     }
		//     @include media-breakpoint-up(sm) {
		//         min-height: 6rem;
		//     }
		.content-detail__arrow {
			img {
				width: 16px !important;
				height: 16px !important;
			}
		}
		.item-thumbnail__wrapper {
			outline: none;
		}
	}
}
/* END: Content Detail Product */

[role="slick"] {
	.slick-list {
		width: 100%;
	}
	.slick-detail {
		.slick-track {
			@extend .align-items-end;
		}
	}
	.slick-track {
		@extend .d-flex;
		@extend .justify-content-center;
		margin: auto;
	}
}

.bg-transparent {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: $black;
	opacity: $modal-backdrop-opacity;
}

.widget-browse-design {
	.seo-page {
		margin-bottom: 3em;
		.title-page {
			h1 {
				font-size: 1.6em;
				// color: #505050;
			}
			h2 {
				font-family: 'brandon-grotesque', sans-serif;
				font-size: 1.6em;
				font-style: normal;
				font-weight: 700;
				text-align: center;
				text-transform: uppercase;
			}
		}
		.content-desc {
			// font: 400 15px/1.8em brandon-grotesque;
			color: #141414;
			padding: 0 20px;
			.page-section {
				padding-top: 1%;
				h2 {
					font-family: "brandon-grotesque", sans-serif;
					font-size: 1.2em;
					// color: #a8a8a8;
				}
			}
			.page-content {
				// font: 400 15px/1.8em brandon-grotesque;
				color: #141414;
				overflow-wrap: break-word !important;
				word-wrap: break-word !important;

				p {
					font-size: 110%;
					color: #141414;
					margin: 8px 0 18px 0;
					overflow-wrap: break-word !important;
				}
				ul {
					font-size: 110%;
					color: #141414;
					li {
						color: #141414;
					}
					b {
						font-weight: 700;
					}
				}
				h3 {
					text-transform: none;
					font-size: 1.7em;
				}
				h4 {
					font-size: 1.6em;
					text-align: left;
					color: #141414;
					font-weight: 700;
					margin-bottom: 3%;
					margin-top: 3%;
				}
				a {
					font-weight: normal;
					text-decoration: none;
					// border-bottom: 1px solid #141414;
					&:hover {
						color: #999493 !important;
						text-decoration: none;
						border-bottom: 1px solid #fff;
					}
				}
			}
			.highlight-text {
				font-size: 1.1em;
			}
		}
		.content-logo {
			// @media (max-width: @screen-xs-max) { padding: 2em 4.5em 2em 4.5em; }
			// @media (max-width: @screen-sm-max) { padding: 2em 4.5em 2em 4.5em; }
			padding: 2em 0 2em 9em;
		}
		.accordion-title {
			text-align: left;
			padding: 1.2rem 0 2.2rem;
		}
		.accordion-section {
			position: relative;
			color: #333;
			cursor: pointer;
			padding: 1.5rem 0;
			width: 100%;
			border: none;
			text-align: left;
			outline: none;
			font-size: 1.2em;
			font-weight: 600;
			transition: 0.5s ease-in-out;
			border-top: 1px solid #000;
			::after {
				content: "";
				background-image: url('https://assets.paperlust.co/assets/images/frontend/arrow-down-new.png');
				background-size: 20px 20px;
				display: inline-block;
				height: 20px;
				width: 20px;
				float: right;
				margin-right: 3%;
			}
			&.active
			{
				::after {
					background-image: url('https://assets.paperlust.co/assets/images/frontend/arrow-up-new.png');
				}
			}
		}
		.accordion-panel {
			display: none;
			background-color: white;
			overflow: hidden;
			font-family: brandon-grotesque, sans-serif;
			font-style: normal;
			font-weight: 300;
			padding-bottom: 1.5em;
			font-size: 1em;
			margin-bottom: 0px;
		}
	}
}

#readmore {
	cursor: pointer;
}
.center-arrow {
	vertical-align: middle;
	padding-bottom: 8px;
	display: inline-block;
}

/* Class for Disable Menu */
.disableMenu {
	&.searchPage {
		@include media-breakpoint-down(sm) {
			.pl-mega-menu {
				display: none; //disable menu
			}
			.pl-mega-menu ~ .cd-main-content {
				padding-top: 0 !important; //remove padding top;
			}
		}
	}
}

/* add loading for handle vue */

[v-cloak] {
	display: none;
	~ .loading-content {
		display: block !important;
	}
}
[v-cloak]::before {
	content: "loading…";
}

.tooltip {
	.tooltip-brandon {
		font-family: $font-family-brandon;
	}
}

/* button class for tab */
.btn-text {
	cursor: pointer;
	display: block;
	color: $gray-600;
	transition: text-shadow 0.3s;
	&.active {
		@extend .font-weight-bold;
		color: $black;
	}
	&:focus,
	&:active,
	&:visited {
		color: $black;
	}
	&:hover:not(.active) {
		color: $black;
		text-shadow: 0 0 0.15px $gray-600, 0 0 0.15px $gray-600;
	}
}
/* END: button class for tab */
